
import {PartDex as PartDexComponent} from "../AxiePart/PartDex"; 


import React from "react";

export const PartDex = () => {
	return (
		<>
			<h1>Part Dex</h1>
			<PartDexComponent/>
		</>
	)
}