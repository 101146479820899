
export const LAND_BUYERS_1 = [
	"0x19aC31Dd1A08c330a6Abd11cb4f5B4a5BFFBFE1f",
	"0xF0D17cAC77C85832e7BBBF4CC909F322B4bdb725",
	"0x42a60D2f2FfA2150C568010A8D425f0AAD284fd2",
	"0xab4787b17BfB2004C4B074Ea64871dfA238bd50c",
	"0x5385315883542b1cFF7d668519844d87ED000a68",
	"0xF550f641f67000eCc54eBc03E77DD08B67835a5d",
	"0x004F980394e98123E0a3414F7eB25a9F2c123D04",
	"0xE11bCe467F2ffda6d0B67c4070f4710712d1EF60",
	"0xEe8eEeC3E7a5ad82827480A9E00D01B1EC0a9F3B",
	"0xf4A8b052AFbd2584d0a12c1711887EF8b2f4B44A",
	"0x29D86FC15cd40F1c8e6D853c159C480d8674740f",
	"0x3F86f18322a888d9b3adef38f127c941bCcc014D",
	"0x574319e245428c9F064006E49aD5f83947F763Ec",
	"0x51c290e134Ac179635bA5374591C81c1e2F8ab87",
	"0x7A21881bb29F53970ca9cfdecD199789E23F5880"
];