import styled, {css} from 'styled-components';



export const StyledExpansionPanel = styled.div`

	.panel-label {cursor:pointer;}
	.label {color:grey; user-select:none;}
	.panel-label:hover .label{color:#666666;}

`;