

export const breedingCosts:{[breed:number]:number} = {
	1: 700,
	2: 900,
	3: 900,
	4: 1500,
	5: 2400,
	6: 3000,
	7: 3000,
}