import styled from 'styled-components';

//CSS
export const StyledTierList = styled.div`

	display:flex;
	flex-flow:column;

	font-family: "Roboto";


`;